<script>
import VMomCategoryForm from "@/components/Forms/VMomCategoryForm.vue";
import VControl from "@/components/Planning/Helpers/VControl";
import { EventBus } from "@/libraries/EventBus";
import MomCategoryService from "@/services/MomCategoryService";
import VMomTable from "./VMomTable.vue";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    props: {
        project_id: {}
    },
    mixins: [DateRangeMixin],
    components: { VControl, VMomCategoryForm, VMomTable, DateRangePicker },
    data() {
        return {
            loading: true,
            showSection: false,
            showModalMomCategory: false,
            momCats: [],
            dateRange: {
                startDate: null,
                endDate: null
            }
        };
    },
    async created() {
        EventBus.$on("data-tab-open", () => {
            this.showSection = true;
        });
        EventBus.$on("data-tab-close", () => {
            this.showSection = false;
        });

        await this.fetchOverview();
        this.loading = false;
    },
    beforeDestroy() {
        EventBus.$off("data-tab-open");
        EventBus.$off("data-tab-close");
    },
    methods: {
        async fetchOverview() {
            try {
                const { data } = await MomCategoryService.GET({
                    project_id: this.project_id
                });
                this.momCats = data.data;
            } catch (e) {
                console.log("fo e", e);
            }
        },
        /**
         * Close mom modal & reload notes
         */
        async momCloseReload() {
            this.loading = true;
            await this.fetchOverview();
            this.loading = false;
            this.showModalMom = false;
        },
        async catCloseReload() {
            this.loading = true;
            await this.fetchOverview();
            this.loading = false;
            this.showModalMomCategory = false;
        },
        openMomCategory() {
            this.showModalMomCategory = true;
        },
        resetDate() {
            this.dateRange = {
                startDate: null,
                endDate: null
            };
        }
    }
};
</script>

<template>
    <div class="v-mom">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-3">
                        <v-control
                            :show="showSection"
                            @trigger="showSection = !showSection"
                        />

                        <h3 class="pb-0 mb-0">
                            {{ $t("mom.mom") }}
                        </h3>
                    </div>
                    <template v-if="showSection">
                        <card body-classes="px-0">
                            <div
                                class="mx-4 mb-3 d-flex justify-content-between"
                            >
                                <div class="d-flex">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange('dateRange')
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                    <base-button
                                        outline
                                        type="primary"
                                        @click="resetDate"
                                        class="ml-2"
                                        ><i class="far fa-redo"></i
                                    ></base-button>
                                </div>

                                <div>
                                    <base-button
                                        size="sm"
                                        type="primary"
                                        outline
                                        @click="openMomCategory"
                                        >{{
                                            $t("mom.add_category")
                                        }}</base-button
                                    >
                                </div>
                            </div>

                            <template>
                                <v-loader :loading="loading" v-if="loading" />
                                <template v-else-if="momCats.length > 0">
                                    <v-mom-table
                                        v-for="m in momCats"
                                        :key="m.id"
                                        :project-id="project_id"
                                        :category="m.name"
                                        :category-id="m.id"
                                        :search="{dateRange}"
                                    />
                                </template>
                                <div
                                    class="mx-4 mt-4 alert alert-warning"
                                    v-else
                                >
                                    {{ $t("mom.no_moms_found") }}
                                </div>
                            </template>
                        </card>
                    </template>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-mom-category-form
                v-if="showModalMomCategory"
                @close="showModalMomCategory = false"
                @close-reload="catCloseReload"
                :project-id="project_id"
            />
        </portal>
    </div>
</template>
