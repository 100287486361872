<script>
import MomCategoryService from "@/services/MomCategoryService";
import Cloner from "@/libraries/Cloner";

export default {
    props: {
        projectId: {}
    },
    data() {
        return {
            loading: true,
            editing: false,
            cats: [],
            errors: {},
            entity: {
                id: null,
                name: null,
                project_id: null
            }
        };
    },
    created() {
        this.entity.project_id = this.projectId;
        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                await MomCategoryService.POST(this.entity);
                this.$emit("close-reload");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">{{ $t("mom.edit_category") }} </template>
            <template v-else>{{ $t("mom.create_category") }}</template>
        </template>

        <template slot="default">
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="text"
                            name="name"
                            :label="$t('mom.category_name')"
                            :validation-name="$t('mom.category_name')"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <base-button
                            type="danger"
                            :outline="true"
                            @click="deleteEntity"
                            v-if="editing"
                            ><i class="far fa-trash"></i>
                            {{ $t("delete") }}</base-button
                        >
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
