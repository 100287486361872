<script>
import VProgressLog from "./components/VProgressLog";
import VActionList from "./components/VActionList";
import VImportantDates from "./components/VImportantDates";
import VMom from "./components/VMom";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        project_id: {}
    },
    components: { VProgressLog, VActionList, VImportantDates, VMom },
    methods: {
        eventize(type) {
            console.log("EVENTIZE", type);
            if (type === "open") {
                EventBus.$emit("data-tab-open");
            } else {
                EventBus.$emit("data-tab-close");
            }
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button @click="eventize('open')" type="primary" size="sm"
                    ><i class="far fa-plus"></i>
                    {{ $t("milestones.expand_all") }}</base-button
                ><base-button
                    @click="eventize('close')"
                    type="primary"
                    size="sm"
                    ><i class="far fa-minus"></i>
                    {{ $t("milestones.fold_all") }}</base-button
                ></bread-bag
            >
        </base-header>

        <v-important-dates :project_id="project_id" />

        <v-action-list :project_id="project_id" />

        <v-mom :project_id="project_id" />

        <v-progress-log :project_id="project_id" />
    </div>
</template>
