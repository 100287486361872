<script>
/**
 * Right menu for the PLANNING interface
 * Show/hide controlled via ui (vuex)
 *
 */
import { mapMutations, mapGetters } from "vuex";

export default {
    props: {
        projectId: {},
        project: {},
        customer: {}
    },
    data() {
        return {
            tempDisabled: false
        };
    },
    computed: {
        ...mapGetters("ui", ["showRightMenu"]),
        ...mapGetters("auth", ["getMyId"])
    },
    beforeDestroy() {
        document.querySelector(".main-content").style.marginRight = "0px";
    },
    created() {
        this.enforce();
    },
    methods: {
        ...mapMutations("ui", ["setRightMenu"]),
        ...mapMutations("auth", ["getIsAdmin"]),
        toggle() {
            this.setRightMenu(!this.showRightMenu);
            this.enforce();
        },
        enforce() {
            if (this.showRightMenu) {
                document.querySelector(".main-content").style.marginRight =
                    "170px";
            } else {
                document.querySelector(".main-content").style.marginRight =
                    "0px";
            }
        }
    }
};
</script>

<template>
    <div>
        <base-button
            size="sm"
            @click="$emit('flagging')"
            :type="project.count_flags > 0 ? 'warning' : 'default'"
            ><template v-if="project.count_flags > 0">
                {{ project.count_flags }} </template
            ><i class="fas fa-flag"></i
        ></base-button>
        <base-button @click="toggle" type="default" size="sm"
            ><i class="fas fa-bars"></i
        ></base-button>
        <div
            id="vr-menu"
            class="sidenav"
            :class="{ opened: showRightMenu, closed: !showRightMenu }"
        >
            <base-button
                class="mb-4"
                @click="toggle"
                type="link"
                size="sm"
                block
                style="color: white"
                ><i class="fas fa-times"></i> {{ $t("close") }}</base-button
            >
            <router-link
                v-if="tempDisabled"
                :to="{
                    name: 'ProjectPlanningStart',
                    params: { project_id: projectId }
                }"
                >{{ $t("planning.right_menu_start") }}</router-link
            >
            <router-link
                :to="{
                    name: 'ProjectPlanningMilestones',
                    params: { project_id: projectId }
                }"
                >{{ $t("planning.right_menu_milestones") }}</router-link
            >

            <router-link
                v-if="tempDisabled"
                :to="{
                    name: 'ProjectPlanningDataTab',
                    params: { project_id: projectId }
                }"
                >{{ $t("planning.right_menu_project_log") }}</router-link
            >
            <router-link
                v-if="tempDisabled"
                :to="{
                    name: 'ProjectPlanningLinks',
                    params: { project_id: projectId }
                }"
                >{{ $t("planning.right_menu_relevant_links") }}</router-link
            >

            <!-- MKR -->
            <hr style="color: white; background: white;" />
            <router-link :to="{ name: 'ProjectViewTasks' }">{{
                $t("project.activities")
            }}</router-link>

            <router-link v-if="false" :to="{ name: 'ProjectViewExpenses' }">{{
                $t("project.expenses")
            }}</router-link>

            <router-link :to="{ name: 'ProjectViewTransport' }">{{
                $t("project.transport")
            }}</router-link>

            <router-link
                v-if="
                    customer.internal_customer != 1 &&
                        project.project_model != 3 &&
                        ($aclCan($ACL.TIMEREG_MGMT) ||
                            project.user_id == getMyId)
                "
                :to="{ name: 'ProjectViewInvoicing' }"
                >{{ $t("project.invoicing") }}</router-link
            >

            <router-link
                v-if="project.project_model != 3"
                :to="{ name: 'ProjectViewStats' }"
                >{{ $t("project.statistics") }}</router-link
            >

            <router-link
                v-if="project.project_model == 3"
                class="col project-navigation__submenu--box"
                :to="{ name: 'ProjectViewContractStats' }"
                >{{ $t("project.statistics") }}</router-link
            >

            <router-link :to="{ name: 'ProjectViewSettings' }">{{
                $t("project.settings")
            }}</router-link>
        </div>
    </div>
</template>

<style lang="scss">
#vr-menu {
    &.opened {
        width: 170px;
    }

    &.closed {
        width: 0px;
    }

    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: $default;
    overflow-x: hidden;
    padding-top: 5rem;
    transition: 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    text-align: center;

    &.sidenav a {
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 6px;
        padding: 0px;
        text-decoration: none;
        color: white;
        display: block;
        transition: 0.4s;
        white-space: nowrap;
        font-size: 13px;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;

        &.active {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}
</style>
