<script>
import Cloner from "@/libraries/Cloner";
import JournalService from "@/services/JournalService";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        context: {},
        contextId: {}
    },
    data() {
        return {
            editing: false,
            isView: true,
            loading: false,
            closeReload: false,
            journals: [],
            journal: {
                id: null,
                note: "",
                project_id: null,
                customer_id: null,
                project_stage_id: null,
                project_milestone_id: null,
                project_subitem_id: null,
                contract_id: null
            },
            errors: {}
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"])
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        editPost(j) {
            this.journal = Cloner.pickClone(j, ["id", "note"]);
            this.editing = true;
            this.isView = false;
        },
        deletePost(j) {
            this.$swal({
                icon: "warning",
                text: this.$t("journals.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t("journals.notify_journal_deleted"),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        await JournalService.DELETE(j.id);
                        
                        this.closeReload = true;
                        this.fetchData();
                        EventBus.$emit("planning-refresh");
                        EventBus.$emit("journal-refresh");
                        // Just remove - no need to reload
                    } catch (e) {}
                }
            });
        },
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await JournalService.GET_indexCtx(
                    this.context,
                    this.contextId
                );
                this.journals = data.data;
            } catch (e) {
                console.log("fe e", e);
            }

            this.loading = false;
        },
        async saveForm() {
            console.log("Svae form");
            let payload = Cloner.clone(this.journal);
            payload[this.context] = this.contextId;

            try {
                if (this.editing) {
                    const resp = await JournalService.PUT(payload);
                } else {
                    const resp = await JournalService.POST(payload);
                }

                this.closeReload = true;
                this.fetchData();
                EventBus.$emit("planning-refresh");
                EventBus.$emit("journal-refresh");
            } catch (e) {
                // Catch errors
                console.log("fe e", e);
            }
            this.isView = true;
            this.resetForm();
        },
        resetForm() {
            this.journal = {
                id: null,
                note: "",
                project_id: null,
                customer_id: null,
                project_stage_id: null,
                project_milestone_id: null,
                project_subitem_id: null,
                contract_id: null
            };
        },
        cancelForm() {
            this.isView = true;
            this.editing = false;
            this.resetForm();
        }
    }
};
</script>

<template>
    <modal size="xl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <div>{{ $t("journals.journal_index") }}</div>
        </template>

        <template slot="default">
            <div class="text-right mb-4" v-if="isView">
                <base-button size="sm" type="primary" @click="isView = !isView"
                    ><i class="far fa-plus"></i>
                    {{ $t("journals.add_journal") }}</base-button
                >
            </div>

            <!-- View interface -->
            <template v-if="isView">
                <v-loader :loading="loading" v-if="loading" />
                <template v-else-if="journals.length > 0">
                    <div class="row" v-for="j in journals" :key="j.id">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <div
                                        class="d-flex justify-content-between align-items-center"
                                    >
                                        <div>
                                            <span class="text-bold"
                                                >#{{ j.id }}</span
                                            >
                                            {{ $t("journals.by") }}
                                            <span class="text-bold">{{
                                                j.user.name
                                            }}</span>
                                        </div>

                                        <div
                                            v-if="
                                                parseInt(j.user_id) === getMyId
                                            "
                                        >
                                            <base-button
                                                @click="deletePost(j)"
                                                type="danger"
                                                outline
                                                size="sm"
                                                ><i class="far fa-trash"></i
                                            ></base-button>
                                            <base-button
                                                @click="editPost(j)"
                                                type="primary"
                                                outline
                                                size="sm"
                                                ><i class="far fa-pencil"></i
                                            ></base-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">
                                        <span class="text-muted"
                                            >{{ $t("journals.created") }}:
                                            {{ $d(new Date(j.created)) }}</span
                                        >
                                        <span
                                            class="text-warning ml-2"
                                            v-if="j.created != j.modified"
                                            >({{
                                                $t("journals.last_modified")
                                            }}:
                                            {{
                                                $d(new Date(j.modified))
                                            }})</span
                                        >
                                    </h5>
                                    <v-newlines :text="j.note" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="alert alert-warning">
                        {{ $t("journals.journal_has_no_data") }}
                    </div>
                </template>
            </template>
            <!-- Create & edit interface -->
            <template v-else>
                <FormulateForm
                    v-model="journal"
                    @submit="saveForm"
                    :errors="errors"
                >
                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                name="note"
                                type="textarea"
                                :placeholder="$t('journals.note_placeholder')"
                                :help="$t('journals.note_help')"
                                :label="$t('journals.note')"
                                validation="required"
                                rows="10"
                            />
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="cancelForm"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </FormulateForm>
            </template>
        </template>
    </modal>
</template>
