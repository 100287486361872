var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-important-dates"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('v-control',{attrs:{"show":_vm.showSection},on:{"trigger":function($event){_vm.showSection = !_vm.showSection}}}),_c('h3',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("actions.action_log"))+" ")])],1),(_vm.showSection)?[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"mx-4 mb-3 d-flex justify-content-between"},[_c('div',[_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'active'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('active')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_active")))]),_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'done'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('done')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_done")))]),_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'all'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('all')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_all")))])],1),_c('div',[_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.setContext({ empty: true })}}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("actions.add_new")))])],1)]),(_vm.actions.length > 0)?_c('div',[_c('v-table',{staticClass:"table table-mg table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',{staticStyle:{"width":"50px"}},[_c('i',{staticClass:"far fa-exclamation-triangle"})]),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"description"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "actions.hdr_description" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"deadline"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "actions.hdr_deadline" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"Users.name"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t("actions.hdr_user")))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"status"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t("actions.hdr_status")))])],1),_c('v-th')],1)],1),_c('v-tbody',_vm._l((_vm.actions),function(a){return _c('v-tr',{key:a.id,class:{
                                            'task-done': a.status === 5
                                        }},[_c('v-td',[(a.priority)?_c('i',{staticClass:"fas fa-exclamation-triangle text-danger"}):_vm._e()]),_c('v-td',[_c('v-newlines',{attrs:{"text":a.description}})],1),_c('v-td',[(a.deadline)?[_vm._v(" "+_vm._s(_vm.$d( new Date( a.deadline ), "short" ))+" ")]:[_vm._v("---")]],2),_c('v-td',[_vm._v(_vm._s(a.user.name)+" ")]),_c('v-td',[_c('i',{staticClass:"fa-check-circle fa-fw fa-lg m-click",class:{
                                                    'far text-muted':
                                                        a.status === 0,
                                                    'fas text-success':
                                                        a.status === 5
                                                },on:{"click":function($event){return _vm.toggleStatus(a)}}})]),_c('v-td',{staticStyle:{"width":"100px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('v-context-comments',{attrs:{"context":"action_id","context-id":a.id,"count":a.count_journals}})],1),_c('div',{staticClass:"col-6"},[_c('i',{staticClass:"fa-pencil fa-fw far m-click",on:{"click":function($event){return _vm.setContext(a)}}})])])])],1)}),1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)],1):_c('div',{staticClass:"mx-4 alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("actions.no_actions_found"))+" ")])])]:_vm._e()],2)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showModal)?_c('v-action-form',{attrs:{"context":_vm.context,"project-id":_vm.project_id},on:{"close":function($event){_vm.showModal = false},"close-reload":_vm.closeReload}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }