<script>
import MomCategoryService from "@/services/MomCategoryService";
import ProjectMomService from "@/services/ProjectMomService";
import TipTap from "@/components/Editor/TipTap";
import Cloner from "@/libraries/Cloner";
import { mapGetters } from "vuex";

export default {
    props: {
        context: {},
        projectId: {},
        momCategoryId: {}
    },
    components: { TipTap },
    data() {
        return {
            editing: false,
            loading: true,
            categories: [],
            saving: false,
            entity: {
                id: null,
                mom_category_id: "",
                project_id: null,
                user_id: null,
                notes: null,
                participants: null
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"])
    },
    async created() {
        if (!this.context.empty) {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "mom_category_id",
                "project_id",
                "user_id",
                "notes",
                "participants"
            ]);
        } else {
            this.entity.project_id = this.projectId;
            this.entity.mom_category_id = this.momCategoryId;
        }
        await this.fetchCategories();
        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    await ProjectMomService.PUT(this.entity);
                } else {
                    await ProjectMomService.POST(this.entity);
                }
                this.$emit("close-reload");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        async fetchCategories() {
            try {
                const { data } = await MomCategoryService.GET_dropdown(this.projectId);
                this.categories = data.data;
            } catch (e) {
                console.log("fc e", e);
            }
        },
        async deleteEntity() {
            this.$swal({
                icon: "warning",
                text: this.$t("mom.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t("mom.notify_mom_deleted"),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        await ProjectMomService.DELETE(this.entity.id);

                        this.$emit("close-reload");
                    } catch (e) {}
                }
            });
        }
    }
};
</script>

<template>
    <modal
        size="xl"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">{{ $t("mom.edit_mom") }} </template>
            <template v-else>{{ $t("mom.create_mom") }}</template>
        </template>

        <template slot="default">
            <FormulateForm :errors="errors" @submit="saveForm" v-model="entity">
                <div class="row">
                    <FormulateErrors />

                    <div class="col-12">
                        <FormulateInput
                            name="mom_category_id"
                            type="select"
                            disabled
                            :options="categories"
                            :label="$t('mom.category')"
                            :placeholder="$t('mom.placeholder_category')"
                            :validation-name="$t('mom.category')"
                            validation="required"
                        />
                    </div>
                    <div class="col-12">
                        <tip-tap v-model.lazy="entity.notes" />
                        <div class="row mt-4">
                            <div class="col">
                                <base-button
                                    type="danger"
                                    :outline="true"
                                    @click="deleteEntity"
                                    v-if="editing && entity.user_id == getMyId"
                                    ><i class="far fa-trash"></i>
                                    {{ $t("delete") }}</base-button
                                >
                            </div>
                            <div class="col text-right">
                                <base-button
                                    type="secondary"
                                    :outline="true"
                                    @click="$emit('close')"
                                    >{{ $t("cancel") }}</base-button
                                >
                                <base-button
                                    type="primary"
                                    :loading="saving"
                                    native-type="submit"
                                    >{{ $t("save") }}</base-button
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-4" v-if="false">
                        Reserved for participants
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
