<script>
export default {
    props: {
        dt: {}
    },
    data() {
        return {
            maxH: 70,
            currH: 0,
            isOpen: false,
            hover: false
        };
    },
    watch: {
        dt(nV) {
            this.$nextTick(() => {
                this.sh();
            });
        }
    },
    mounted() {
        this.sh();
    },
    methods: {
        sh() {
            if (this.$refs.nc) {
                this.currH = this.$refs.nc.scrollHeight;
            }
        },
        toggle() {
            if (!(this.currH > this.maxH)) {
                return;
            }

            this.isOpen = !this.isOpen;
        }
    }
};
</script>
<template>
    <div class="v-smart-html">
        <div
            @mouseover="hover = true"
            @mouseleave="hover = false"
            class="v-smart-html__data"
            :class="{
                'is-closed': !isOpen,
                'is-overflown': currH > maxH && !isOpen,
                'is-hover m-click': currH > maxH && hover
            }"
            @click="toggle"
            v-html="dt"
            ref="nc"
        ></div>
        <i class="excla far fa-exclamation-triangle" v-if="currH > maxH && !isOpen"></i>
    </div>
</template>

<style lang="scss">
.v-smart-html {
    position: relative;
    .hider-opener {
        text-align: left;
        margin-top: 0px;

        span {
            color: $gray-600;
        }
    }
    .excla {
        display: block;
        position: absolute;
        
        color: $warning;
        left: -7px;
        bottom: 0;
    }


    &__data {        
        &.is-hover {
            background: rgba(0, 0, 0, 0.1);
            border: 1px dashed $gray-500;
        }

        &.is-overflown {
            border-left: 1px dashed $gray-600;
            padding-left: 10px;
        }
        &.is-closed {
            max-height: 70px;
            overflow: hidden;
        }

        * {
            font-size: 11px;
        }
    }
}
</style>
