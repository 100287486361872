<script>
export default {};
</script>

<template>
    <div class="v-planning v-planning-start">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="text-center mt-6">
                        <img src="/img/planning/test.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
