<script>
import VControl from "./Helpers/VControl";
import VMilestone from "./VMilestone";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        stage: {},        
    },
    components: { VControl, VMilestone },
    data() {
        return {
            show: true
        };
    },
    created() {
        EventBus.$on("milestones-board", this.handleMilestonesBoard);
    },
    beforeDestroy() {
        EventBus.$off("milestones-board", this.handleMilestonesBoard);
    },
    methods: {
        handleMilestonesBoard({ action }) {
            if (action === "open") {
                this.show = true;
            } else {
                this.show = false;
            }
        },
        triggerShow(newShow) {
            this.show = newShow;
        }
    }
};
</script>

<template>
    <card body-classes="">
        <div class="v-stage">
            <div class="v-stage_top">
                <v-control :show="show" @trigger="triggerShow" />
                {{ stage.name }}
                <div class="v-stage_top-subtitle">{{ stage.governance }}</div>
            </div>

            <div class="vp-milestones-container" v-if="show">
                <v-milestone
                    v-for="milestone in stage.project_milestones"
                    :key="milestone.id"
                    :milestone="milestone"
                    :stage="stage"
                />
            </div>
        </div>
    </card>
</template>

<style lang="scss">
.v-stage {
    &_top {
        font-size: 18px;
        font-weight: bold;

        &-subtitle {
            display: inline;
            font-weight: normal;
            color: $gray-600;
            font-size: 15px;
        }
    }

    .vp-milestones-container {
        margin-top: 15px;
        padding-left: 0px;
    }
}
</style>
