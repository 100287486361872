var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-important-dates"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('v-control',{attrs:{"show":_vm.showSection},on:{"trigger":function($event){_vm.showSection = !_vm.showSection}}}),_c('h3',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("important_dates.important_dates"))+" ")])],1),(_vm.showSection)?[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"mx-4 mb-3 d-flex justify-content-between"},[_c('div',[_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'upcoming'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('upcoming')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_active")))]),_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'past'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('past')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_done")))]),_c('base-button',{attrs:{"size":"sm","type":_vm.selectedFilter == 'all'
                                            ? 'primary'
                                            : 'secondary'},on:{"click":function($event){return _vm.filter('all')}}},[_vm._v(_vm._s(_vm.$t("actions.filter_all")))])],1),_c('div',[_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.setContext({ empty: true })}}},[_vm._v(_vm._s(_vm.$t("important_dates.add_date")))])],1)]),(_vm.dates.length > 0)?[_c('v-table',{staticClass:"table table-mg table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"ImportantDates.description"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "important_dates.tbl_hdr_description" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"ImportantDates.date_start"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "important_dates.tbl_hdr_dates" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"ImportantDates.count_journals"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_c('i',{staticClass:"far fa-comments"})])],1),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t( "project_links.tbl_hdr_actions" )))])],1)],1),_c('v-tbody',_vm._l((_vm.dates),function(l){return _c('v-tr',{key:l.id,class:{
                                            'date-is-past': _vm.isPast(l)
                                        }},[_c('v-td',[_c('v-newlines',{attrs:{"text":l.description}})],1),_c('v-td',[(l.date_end)?[_vm._v(" "+_vm._s(_vm.$d( new Date( l.date_start ), "short" ))+" - "+_vm._s(_vm.$d( new Date( l.date_end ), "short" ))+" ")]:[_vm._v(" "+_vm._s(_vm.$d( new Date( l.date_start ), "short" ))+" ")]],2),_c('v-td',[_c('v-context-comments',{attrs:{"context":"important_date_id","context-id":l.id,"count":l.count_journals}})],1),_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"far m-click fa-pencil fa-lg",on:{"click":function($event){return _vm.setContext(l)}}})])],1)}),1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)]:_c('div',{staticClass:"mx-4 alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("important_dates.no_dates_found"))+" ")])],2)]:_vm._e()],2)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showForm)?_c('v-important-date-form',{attrs:{"context":_vm.context,"project-id":_vm.project_id},on:{"close":function($event){_vm.showForm = _vm.close},"close-reload":_vm.closeReload}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }