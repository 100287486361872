<script>
import VProjectMomForm from "@/components/Forms/VProjectMomForm.vue";
import ProjectMomService from "@/services/ProjectMomService";
import SmartHtml from "@/components/SmartHtml";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        category: {
            type: String
        },
        categoryId: {},
        projectId: {},
        search: {}
    },
    components: { SmartHtml, VProjectMomForm },
    data() {
        return {
            loading: true,
            notes: [],
            meta: {},
            showModal: false,
            context: {}
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"])
    },
    watch: {
        search: function(n) {
            this.fetchData();
        }
    },
    async created() {
        EventBus.$on("moms-reload", this.fetchData);

        await this.fetchData();
        this.loading = false;
    },
    beforeDestroy() {
        EventBus.$off("moms-reload");
    },
    methods: {
        async fetchData() {
            try {
                this.apiPaginator.sort = "created";
                this.apiPaginator.direction = "desc";
                this.apiPaginator.limit = "5";

                let params = this.$buildParams();
                params["dateRange"] = this.search.dateRange;
                params["project_id"] = this.projectId;
                params["mom_category_id"] = this.categoryId;
                const { data } = await ProjectMomService.GET(params);
                this.notes = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        closeReload() {
            this.showModal = false;
            this.fetchData();
        },
        edit(ctx) {
            this.context = ctx;
            this.showModal = true;
        }
    }
};
</script>

<template>
    <div class="v-mom-table">
        <h3 class="mx-4">{{ category }}</h3>

        <v-loader :loading="loading" v-if="loading" />
        <template v-else>
            <v-table class="table table-mg table-sm ">
                <v-thead>
                    <v-tr>
                        <v-th style="width: 120px;">{{
                            $t("mom.tbl_hdr_date")
                        }}</v-th>
                        <v-th>{{ $t("mom.tbl_hdr_note") }}</v-th>
                        <v-th style="width: 240px;">{{
                            $t("mom.tbl_hdr_author")
                        }}</v-th>
                        <v-th style="width: 90px;"></v-th>
                    </v-tr>
                </v-thead>

                <v-tbody>
                    <v-tr v-for="n in notes" :key="n.id">
                        <v-td>{{ $d(new Date(n.created), "short") }}</v-td>
                        <v-td><smart-html :dt="n.notes"/></v-td>
                        <v-td>{{ n.user.name }}</v-td>
                        <v-td
                            ><i
                                v-if="getMyId == n.user_id"
                                class="far fa-pencil fa-lg m-click"
                                @click="edit(n)"
                            ></i
                        ></v-td>
                    </v-tr>
                </v-tbody>
                <v-tfoot>
                    <v-tr>
                        <v-td></v-td>
                        <v-td colspan="3" class="text-muted">
                            <span class="m-click" @click="edit({ empty: true })"
                                ><i class="far fa-plus"></i>
                                {{
                                    $t("mom.add_note_to_CAT", { CAT: category })
                                }}</span
                            ></v-td
                        >
                    </v-tr>
                </v-tfoot>
            </v-table>
            <div class="paginator" v-if="meta.pagination.total_pages > 1">
                <base-pagination
                    :page-count="meta.pagination.total_pages"
                    :per-page="meta.pagination.per_page"
                    :value="meta.pagination.current_page"
                    @input="$handlePagination($event)"
                />
            </div>
            <hr />
        </template>
        <portal to="modals">
            <v-project-mom-form
                v-if="showModal"
                :context="context"
                :project-id="projectId"
                :mom-category-id="categoryId"
                @close-reload="closeReload"
                @close="showModal = false"
            />
        </portal>
    </div>
</template>
