<script>
export default {
    props: {
        context: {}
    },
    data() {
        return {
            hover: false
        };
    }
};
</script>

<template>
    <span>
        <span
            v-if="context"
            class="text-primary text-bold"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            ><i class="far fa-ballot"></i> DC
            <div v-if="hover" class="text-default small d-inline">
                {{ context }}
            </div></span
        >
    </span>
</template>
