<script>
import ProjectSubitemService from "@/services/ProjectSubitemService";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        subitem: {
            description: "The whole project_subitem entity"
        }
    },
    methods: {
        async changeStatus() {
            const payload = {
                id: this.subitem.id,
                status: this.subitem.status == 1 ? 0 : 1
            };

            try {
                await ProjectSubitemService.PUT_setStatus(payload);
                EventBus.$emit("planning-refresh");
                this.$notify({
                    message: this.$t("project_subitem.notify_status_changed"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
            } catch (e) {
                console.log("swal e", e);
            }
        }
    }
};
</script>

<template>
    <div class="v-subitem-status-component d-flex align-items-center">
        <div>
            <i
                @click="changeStatus"
                class="fa-check-circle fa-fw m-click"
                :class="{
                    'fas text-success': subitem.status === 1,
                    'far fa-muted': subitem.status !== 1
                }"
            ></i>
        </div>

        <div class="user-name text-muted ml-2" v-if="'user' in subitem">
            {{ subitem.user.name }}
        </div>
        <div class="status-modified text-muted ml-2" v-if="'user' in subitem">
            <i class="far fa-calendar"></i>
            {{ $d(new Date(subitem.status_modified)) }}
        </div>
    </div>
</template>

<style lang="scss">
.v-subitem-status-component {
    .user-name {
        font-size: 10px;
    }

    .status-modified {
        font-size: 10px;
    }
}

.v-subitem-status {
    padding-left: 15px;
    padding-right: 15px;
}
</style>
