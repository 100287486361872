<script>
import VImportantDateForm from "@/components/Forms/VImportantDateForm";
import ImportantDateService from "@/services/ImportantDateService";
import { mapGetters } from "vuex";
import VContextComments from "@/components/Planning/Helpers/VContextComments";
import { EventBus } from "@/libraries/EventBus";
import VControl from "@/components/Planning/Helpers/VControl";

export default {
    props: {
        project_id: {}
    },
    components: { VImportantDateForm, VContextComments, VControl },
    data() {
        return {
            loading: true,
            showForm: false,
            dates: [],
            meta: {},
            selectedFilter: "upcoming",
            showSection: true
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"])
    },
    async created() {
        this.apiPaginator.sort = "ImportantDates.date_start";
        this.apiPaginator.direction = "asc";
        // this.apiSearchFilter = {
        //     search: "",
        //     favorites_user_id: null,
        //     invoice_setup: this.getInvoiceSetup,
        // };
        // this.$apiEnableWatcher();
        await this.fetchData();
        this.loading = false;
        EventBus.$on("journal-refresh", this.fetchData);
        EventBus.$on("data-tab-open", () => {
            this.showSection = true;
        });
        EventBus.$on("data-tab-close", () => {
            this.showSection = false;
        });
    },
    beforeDestroy() {
        EventBus.$off("journal-refresh", this.fetchData);
        EventBus.$off("data-tab-open");
        EventBus.$off("data-tab-close");
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                params["filter"] = this.selectedFilter;
                const { data } = await ImportantDateService.GET(params);
                this.dates = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fde", e);
            }
        },
        closeReload() {
            this.fetchData();
            this.showForm = false;
        },
        setContext(ctx) {
            this.context = ctx;
            this.showForm = true;
        },
        isPast(id) {
            const now = new Date();
            let compare;

            if (id.date_end) {
                compare = id.date_end;
            } else {
                compare = id.date_start;
            }

            if (now > new Date(compare)) {
                return true;
            } else {
                return false;
            }
        },
        filter(filterAction) {
            this.selectedFilter = filterAction;
            this.fetchData();
        }
    }
};
</script>

<template>
    <div class="v-important-dates">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-3">
                        <v-control
                            :show="showSection"
                            @trigger="showSection = !showSection"
                        />
                        <h3 class="pb-0 mb-0">
                            {{ $t("important_dates.important_dates") }}
                        </h3>
                    </div>
                    <template v-if="showSection">
                        <v-loader :loading="loading" v-if="loading" />
                        <card body-classes="px-0" v-else>
                            <div
                                class="mx-4 mb-3 d-flex justify-content-between"
                            >
                                <div>
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'upcoming'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('upcoming')"
                                        >{{
                                            $t("actions.filter_active")
                                        }}</base-button
                                    >
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'past'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('past')"
                                        >{{
                                            $t("actions.filter_done")
                                        }}</base-button
                                    >
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'all'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('all')"
                                        >{{
                                            $t("actions.filter_all")
                                        }}</base-button
                                    >
                                </div>

                                <div>
                                    <base-button
                                        size="sm"
                                        type="primary"
                                        @click="setContext({ empty: true })"
                                        >{{
                                            $t("important_dates.add_date")
                                        }}</base-button
                                    >
                                </div>
                            </div>

                            <template v-if="dates.length > 0">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="ImportantDates.description"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "important_dates.tbl_hdr_description"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="ImportantDates.date_start"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "important_dates.tbl_hdr_dates"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>

                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="ImportantDates.count_journals"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    ><i
                                                        class="far fa-comments"
                                                    ></i
                                                ></v-sorter>
                                            </v-th>

                                            <v-th class="text-center">{{
                                                $t(
                                                    "project_links.tbl_hdr_actions"
                                                )
                                            }}</v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="l in dates"
                                            :key="l.id"
                                            :class="{
                                                'date-is-past': isPast(l)
                                            }"
                                        >
                                            <v-td>
                                                <v-newlines
                                                    :text="l.description"
                                                />
                                            </v-td>
                                            <v-td>
                                                <template v-if="l.date_end">
                                                    {{
                                                        $d(
                                                            new Date(
                                                                l.date_start
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $d(
                                                            new Date(
                                                                l.date_end
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>
                                                    {{
                                                        $d(
                                                            new Date(
                                                                l.date_start
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                            </v-td>
                                            <v-td>
                                                <v-context-comments
                                                    context="important_date_id"
                                                    :context-id="l.id"
                                                    :count="l.count_journals"
                                                />
                                            </v-td>

                                            <v-td class="text-center"
                                                ><i
                                                    class="far m-click fa-pencil fa-lg"
                                                    @click="setContext(l)"
                                                ></i
                                            ></v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>

                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </template>
                            <div class="mx-4 alert alert-warning" v-else>
                                {{ $t("important_dates.no_dates_found") }}
                            </div>
                        </card>
                    </template>
                </div>
            </div>
        </div>

        <portal to="modals">
            <v-important-date-form
                @close="showForm = close"
                @close-reload="closeReload"
                :context="context"
                :project-id="project_id"
                v-if="showForm"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-important-dates {
    .date-is-past {
        td {
            color: $gray-600;
            background-color: #fafafa;
            //1color: $gray-700 !important;
        }
    }
}
</style>
