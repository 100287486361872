<script>
import VDocumentationForm from "./VDocumentationForm.vue";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        doc: {},
        milestoneId: {}
    },
    components: { VDocumentationForm },
    data() {
        return {
            hover: false,
            showModal: false
        };
    },
    computed: {
        exist() {
            if (this.doc) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>

<template>
    <div class="v-documentation">
        <i
            @click="showModal = true"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            class="far fa-fw m-click"
            :class="{
                'fa-file-alt': !hover,
                'fa-file-plus m-click open-docs': hover,
                'fa-muted': !exist
            }"
        ></i>
        <portal to="modals">
            <v-documentation-form
                @close="showModal = false"
                v-if="showModal"
                :context="{ id: milestoneId, documentation_text: doc }"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-documentation {
    .open-docs.fa-muted,
    .open-docs {
        color: $gray-800 !important;
    }
}
</style>
