<script>
/**
 * Timeline component for showing dates on milestones
 */
export default {
    props: {
        milestone: {},
        progress: {},
        status: {}
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        canRender() {
            if (!this.milestone.start_date || !this.milestone.end_date) {
                return false;
            } else {
                return true;
            }
        },
        showFlag() {
            if (
                "project_flags" in this.milestone &&
                this.milestone.project_flags.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        colorStatus() {
            if (this.status !== 5) {
                // Not ready
                if (this.progress >= 75 && this.progress < 95) {
                    return "warn";
                } else if (this.progress >= 95) {
                    return "passed";
                }
            } else {
                // Ready
                return "ok";
            }
        },
        barColor() {
            switch (this.colorStatus) {
                case "warn":
                    return "bg-warning";
                    break;

                case "passed":
                    return "bg-danger";
                    break;

                case "ok":
                    return "bg-primary";
                    break;
            }
        },
        textColor() {
            switch (this.colorStatus) {
                case "warn":
                    return "";
                    break;

                case "passed":
                    return "text-white";
                    break;

                case "ok":
                    return "text-white";
                    break;
            }
        }
    },
    methods: {
        handleClick() {
            this.$emit("timeline-editor");
        }
    }
};
</script>

<template>
    <div class="v-timeline">
        <template v-if="milestone.include_gantt === 1">
            <template v-if="canRender">
                <div class="d-flex align-items-center">
                    <div class="timeline-flag" v-if="showFlag">
                        <i class="fas fa-flag text-danger"></i>
                    </div>
                    <div class="timeline-container">
                        <div
                            class="progress position-relative m-click"
                            style="height: 30px;"
                            @click="handleClick"
                        >
                            <div
                                class="progress-bar"
                                :class="barColor"
                                role="progressbar"
                                :style="{ width: `${progress}%` }"
                            ></div>
                            <div
                                class="v-timeline_dates justify-content-center d-flex position-absolute w-100"
                                :class="textColor"
                                @mouseover="hover = true"
                                @mouseleave="hover = false"
                            >
                                <div
                                    class="v-timeline_dates-start_date"
                                    :class="{ 'make-visible': hover }"
                                >
                                    {{
                                        $d(
                                            new Date(milestone.start_date),
                                            "short"
                                        )
                                    }}
                                </div>
                                <div>
                                    <div class="v-timeline_dates-week">
                                        {{ milestone.start_week }}
                                    </div>
                                    <div class="v-timeline_dates-year">
                                        {{ milestone.start_week_year }}
                                    </div>
                                </div>

                                <i class="fa fa-arrows-h"></i>
                                <div>
                                    <div class="v-timeline_dates-week">
                                        {{ milestone.end_week }}
                                    </div>
                                    <div class="v-timeline_dates-year">
                                        {{ milestone.end_week_year }}
                                    </div>
                                </div>
                                <div
                                    class="v-timeline_dates-end_date"
                                    :class="{ 'make-visible': hover }"
                                >
                                    {{
                                        $d(
                                            new Date(milestone.end_date),
                                            "short"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="alert alert-outline alert-planning m-click"
                    @click="handleClick"
                >
                    <i class="far fa-exclamation-triangle"></i>
                    {{ $t("planning.no_dates_defined") }}
                </div>
            </template>
        </template>
        <template v-else>---</template>
    </div>
</template>

<style lang="scss">
.v-timeline {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    .alert-planning {
        margin: 0;
        height: 100%;
        font-size: 11px;
        padding-top: 4px;
        padding-bottom: 4px;
        border: 1px solid $gray-400;
        color: $gray-600;
    }

    .timeline-flag {
        text-align: left;
        flex: 0 0 22px;
    }

    .timeline-container {
        flex: 1 1;
    }

    .progress {
        margin-bottom: 0px;
    }

    &_dates {
        margin-top: 4px;
        font-size: 12px;
        line-height: 12px;

        &-start_date {
            font-size: 9px;
            margin-right: 10px;
            margin-top: 6px;
            font-weight: 600;
            color: transparent;
        }

        &-end_date {
            font-size: 9px;
            margin-left: 10px;
            margin-top: 6px;
            font-weight: 600;
            color: transparent;
        }

        &-end_date.make-visible,
        &-start_date.make-visible {
            color: inherit;
        }

        &-week {
            font-size: 12px;
            font-weight: 600;
        }
        &-year {
            font-size: 9px;
        }

        .fa {
            // border: 1px solid red;
            margin-top: 6px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &_arrow {
        font-size: 7px;
    }

    &_from {
        font-size: 12px;
    }

    &_to {
        font-size: 12px;
    }
}
</style>
