import i18n from "@/i18n";

const JournalTypes = {
    project_stage: i18n.t("journals.type_stage"),
    project_milestone: i18n.t("journals.project_milestone"),
    project_subitem: i18n.t("journals.project_subitem"),
    contract: i18n.t("journals.contract"),
    action: i18n.t("journals.action"),
    important_date: i18n.t("journals.important_date"),
    project_link: i18n.t("journals.project_link"),
    project: i18n.t("journals.project"),
    customer: i18n.t("journals.customer")
};

export { JournalTypes };
