<script>
export default {
    props: {
        context: {}
    },
    computed: {
        depString() {
            if (this.context) {
                const parsed = this.parseDependency(this.context);
                return `${parsed[0]}${parsed[1]}`;
            } else {
                return "";
            }
        },
        depOffset() {
            if (this.context) {
                const parsed = this.parseDependency(this.context);
                if (parsed[2] != 0) {
                    if (parsed[2] < 0) {
                        return `${parsed[2]}` + this.$t("planning.week_short");
                    } else {
                        return `+${parsed[2]}u.`;
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        }
    },
    methods: {
        parseDependency(dependency) {
            const regex = /([0-9]+)([SE])([-+][0-9]+)?/;
            const matches = dependency.match(regex);

            if (matches) {
                return [
                    matches[1], // the numeric part
                    matches[2], // "S" or "E"
                    matches[3] ? parseInt(matches[3], 10) : 0 // offset or 0 if not present
                ];
            } else {
                // Return a default value if no match is found
                return [null, null, 0];
            }
        }
    }
};
</script>

<template>
    <div class="v-dependency">
        <template v-if="context">
            {{ context }}
        </template>
        <template v-else>---</template>
    </div>
</template>

<style lang="scss">
.v-dependency {
    &__string {
    }

    &__offset {
        font-size: 10px;
    }
}
</style>
