<script>
import Cloner from "@/libraries/Cloner";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        context: {}
    },
    data() {
        return {
            errors: {},
            entity: {
                id: "",
                documentation_text: ""
            }
        };
    },
    created() {
        this.entity = Cloner.clone(this.context);
    },
    methods: {
        async saveForm() {
            try {
                const { data } = await ProjectMilestoneService.PUT(this.entity);
                EventBus.$emit("planning-refresh");
                this.$emit('close');
            } catch (e) {
                console.log("save e ", e);
            }
        }
    }
};
</script>

<template>
    <modal size="xl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            {{ $t("project_milestone.documentation") }}
        </template>

        <template slot="default">
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="documentation_text"
                            type="textarea"
                            :placeholder="
                                $t(
                                    'project_milestone.documentation_placeholder'
                                )
                            "
                            :help="$t('project_milestone.documentation_help')"
                            :label="$t('project_milestone.documentation')"
                            rows="10"
                        />
                    </div>
                </div>
                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
