var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-button',{attrs:{"size":"sm","type":_vm.project.count_flags > 0 ? 'warning' : 'default'},on:{"click":function($event){return _vm.$emit('flagging')}}},[(_vm.project.count_flags > 0)?[_vm._v(" "+_vm._s(_vm.project.count_flags)+" ")]:_vm._e(),_c('i',{staticClass:"fas fa-flag"})],2),_c('base-button',{attrs:{"type":"default","size":"sm"},on:{"click":_vm.toggle}},[_c('i',{staticClass:"fas fa-bars"})]),_c('div',{staticClass:"sidenav",class:{ opened: _vm.showRightMenu, closed: !_vm.showRightMenu },attrs:{"id":"vr-menu"}},[_c('base-button',{staticClass:"mb-4",staticStyle:{"color":"white"},attrs:{"type":"link","size":"sm","block":""},on:{"click":_vm.toggle}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" "+_vm._s(_vm.$t("close")))]),(_vm.tempDisabled)?_c('router-link',{attrs:{"to":{
                name: 'ProjectPlanningStart',
                params: { project_id: _vm.projectId }
            }}},[_vm._v(_vm._s(_vm.$t("planning.right_menu_start")))]):_vm._e(),_c('router-link',{attrs:{"to":{
                name: 'ProjectPlanningMilestones',
                params: { project_id: _vm.projectId }
            }}},[_vm._v(_vm._s(_vm.$t("planning.right_menu_milestones")))]),(_vm.tempDisabled)?_c('router-link',{attrs:{"to":{
                name: 'ProjectPlanningDataTab',
                params: { project_id: _vm.projectId }
            }}},[_vm._v(_vm._s(_vm.$t("planning.right_menu_project_log")))]):_vm._e(),(_vm.tempDisabled)?_c('router-link',{attrs:{"to":{
                name: 'ProjectPlanningLinks',
                params: { project_id: _vm.projectId }
            }}},[_vm._v(_vm._s(_vm.$t("planning.right_menu_relevant_links")))]):_vm._e(),_c('hr',{staticStyle:{"color":"white","background":"white"}}),_c('router-link',{attrs:{"to":{ name: 'ProjectViewTasks' }}},[_vm._v(_vm._s(_vm.$t("project.activities")))]),(false)?_c('router-link',{attrs:{"to":{ name: 'ProjectViewExpenses' }}},[_vm._v(_vm._s(_vm.$t("project.expenses")))]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'ProjectViewTransport' }}},[_vm._v(_vm._s(_vm.$t("project.transport")))]),(
                _vm.customer.internal_customer != 1 &&
                    _vm.project.project_model != 3 &&
                    (_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT) ||
                        _vm.project.user_id == _vm.getMyId)
            )?_c('router-link',{attrs:{"to":{ name: 'ProjectViewInvoicing' }}},[_vm._v(_vm._s(_vm.$t("project.invoicing")))]):_vm._e(),(_vm.project.project_model != 3)?_c('router-link',{attrs:{"to":{ name: 'ProjectViewStats' }}},[_vm._v(_vm._s(_vm.$t("project.statistics")))]):_vm._e(),(_vm.project.project_model == 3)?_c('router-link',{staticClass:"col project-navigation__submenu--box",attrs:{"to":{ name: 'ProjectViewContractStats' }}},[_vm._v(_vm._s(_vm.$t("project.statistics")))]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'ProjectViewSettings' }}},[_vm._v(_vm._s(_vm.$t("project.settings")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }