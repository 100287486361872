<script>
import VStage from "./VStage";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        board: {},
        project: {},
        projectId: {}
    },
    components: { VStage },
    data() {
        return {
            showItems: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getIsAdmin"])
    },
    methods: {
        triggerShow(newShow) {
            this.showItems = newShow;
        },
        eventize(type) {
            EventBus.$emit("milestones-board", { action: type });
        }
    }
};
</script>

<template>
    <div class="v-planning-board">
        <div class="">
            <card>
                <div>
                    <base-button
                        @click="eventize('open')"
                        type="primary"
                        size="sm"
                        ><i class="far fa-plus"></i>
                        {{ $t("milestones.expand_all") }}</base-button
                    ><base-button
                        @click="eventize('close')"
                        type="primary"
                        size="sm"
                        ><i class="far fa-minus"></i>
                        {{ $t("milestones.fold_all") }}</base-button
                    >
                </div>
            </card>
        </div>
        <div class="planning-board">
            <div
                v-for="(stage, stageIdent) in board"
                :key="stage.id"
                class="vp-container"
            >
                <template v-if="project.project_planning === 0">
                    <v-stage :stage="stage" v-if="stageIdent === 0" />
                </template>
                <template v-else>
                    <v-stage :stage="stage" />
                </template>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-planning-board {
    .planning-board {
        .vp-container {
            margin-bottom: 20px;
        }
    }
}
</style>
