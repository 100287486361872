<script>
/**
 * Project view
 * Master element containing sub-routing for a given project
 */
import { mapGetters } from "vuex";
import VDueDateColor from "@/components/Task/VDueDateColor";
import VInfoCard from "@/components/Cards/VInfoCard";
import CustomerService from "@/services/CustomerService";
import ProjectService from "@/services/ProjectService";
import NaviBlock from "@/components/Navigation/NaviBlock";
import ProjectMixin from "@/mixins/ProjectMixin";
import { EventBus } from "@/libraries/EventBus";
import RightMenu from "./RightMenu";
import VFlaggingForm from "@/components/Forms/VFlaggingForm";

export default {
    name: "VProjectView",
    props: {
        project_id: {}
    },
    mixins: [ProjectMixin],
    components: {
        NaviBlock,
        VDueDateColor,
        VInfoCard,
        RightMenu,
        VFlaggingForm
    },
    data() {
        return {
            loading: false,
            project: null,
            customer: null,
            showFlagModal: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getIsAdmin"])
    },
    async created() {
        await this.fetchProject();
        EventBus.$on("project-refresh", this.fetchProject);
    },
    beforeDestroy() {
        EventBus.$off("project-refresh", this.fetchProject);
    },
    methods: {
        async fetchProject() {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
                this.customer = this.project.customer;
                await this.fetchCustomer(this.project.customer.id);
            } catch (e) {
                console.log("Faker", e);
            }
            this.loading = false;
        },
        async fetchCustomer(customer_id) {
            const r = await CustomerService.GETS(customer_id);
            this.customer = r.data.data;
        }
    }
};
</script>

<template>
    <div>
        <v-loader :loading="loading" v-if="loading" />
        <template v-else-if="customer && project">
            <base-header class="pb-1" type="" v-if="true"> </base-header>

            <!-- Here starts the below white-bar area -->
            <div class="container-fluid project-navigation" v-if="project">
                <div class="row">
                    <div class="col-12 project-navigation__title">
                        <h1>
                            {{ project.title }}
                        </h1>

                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="project-navigation__metas">
                                <div class="project-navigation__metas--meta">
                                    <i class="far fa-fw fa-user"></i>
                                    {{ project.responsible_user.name }}
                                </div>

                                <div class="project-navigation__metas--meta">
                                    <i
                                        class="far fa-fw"
                                        :class="{
                                            'fa-wind-turbine':
                                                project.project_type == 1,
                                            'fa-solar-panel':
                                                project.project_type == 2,
                                            'fa-bolt': project.project_type == 4
                                        }"
                                    ></i>
                                    {{ project.project_type_name }}
                                </div>

                                <div class="project-navigation__metas--meta">
                                    <i class="far fa-fw fa-building"></i>
                                    {{ customer.name }}
                                </div>

                                <div class="project-navigation__metas--meta">
                                    {{ $t("project.status") }}:
                                    <span :class="statusColor">
                                        {{ project.status_name }}
                                    </span>
                                </div>

                                <div class="project-navigation__metas--meta">
                                    <v-due-date-color
                                        :context="project.deadline_date"
                                        ><i
                                            class="far fa-fw fa-calendar-star"
                                        ></i
                                    ></v-due-date-color>
                                </div>

                                <template v-if="getIsAdmin">
                                    <div
                                        class="project-navigation__metas--meta"
                                    >
                                        <i class="far fa-fw fa-hashtag"></i>
                                        {{ project.id }}
                                    </div>

                                    <div
                                        class="project-navigation__metas--meta"
                                    >
                                        <i class="far fa-fw fa-sack-dollar"></i>
                                        {{ project.project_model_name }}
                                    </div>
                                </template>
                            </div>
                            <right-menu
                                :project-id="project_id"
                                :project="project"
                                :customer="customer"
                                @flagging="showFlagModal = true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-5" style="position: relative"></div>
            <router-view name="planning_tab"></router-view>
        </template>
        <div v-else class="alert alert-warning">
            {{ $t("project.not_found") }}
        </div>
        <portal to="modals">
            <v-flagging-form
                v-if="showFlagModal"
                :project-id="project_id"
                @close="showFlagModal = false"
            />
        </portal>
    </div>
</template>

<style></style>
