<script>
/**
 * Milestone status widget with build in confirmation tooling
 */
import { MilestoneStatus } from "@/constants/Planning.const";
import Cloner from "@/libraries/Cloner";
import { EventBus } from "@/libraries/EventBus";
import VStatusForm from "./VStatusForm.vue";

export default {
    props: {
        milestone: {}
    },
    components: { VStatusForm },
    data() {
        return {
            showModal: false
        };
    },
    computed: {
        status() {
            return this.milestone.status;
        },
        currentStatus() {
            return `v-status-type-${this.status}`;
        },
        currentStatusText() {
            return MilestoneStatus[this.status];
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        }
    }
};
</script>

<template>
    <div>
        <div class="v-status m-click" :class="currentStatus" @click="openModal">
            {{ currentStatusText }}
        </div>
        <portal to="modals">
            <v-status-form v-if="showModal" @close="closeModal" :context="milestone" />
        </portal>
    </div>
</template>

<style lang="scss">
.v-status {
    font-size: 11px;
    text-align: center;
    color: white;
    font-weight: 600;
    padding: 8px;
    border-radius: 6px;
    text-transform: uppercase;
    border: 1px solid white;

    &:hover {
        border: 1px solid $gray-700;
    }

    &.v-status-type-0 {
        background-color: $gray-600;
    }

    &.v-status-type-1 {
        background-color: $warning;
    }

    &.v-status-type-5 {
        background-color: $primary;
    }
}
</style>
