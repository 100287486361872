<script>
export default {
    props: {
        show: {},
        disable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        fire() {
            if (!this.disable) {
                this.$emit('trigger', !this.show)
            }
        }
        
    }
};
</script>

<template>
    <span class="v-control" @click="fire" :class="{'m-click': !disable, 'v-control-disabled': disable}">
        <i
            class="fa "
            :class="{ 'fa-chevron-right': !show, 'fa-chevron-down': show }"
        ></i>
    </span>
</template>

<style lang="scss">
.v-control {    
    width: 28px;
    display: inline-block;

    &-disabled {
        color: $gray-300;
    }
}
</style>
