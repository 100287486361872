var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-journals"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('v-control',{attrs:{"show":_vm.showSection},on:{"trigger":function($event){_vm.showSection = !_vm.showSection}}}),_c('h3',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("progress_log.project_journal"))+" ")])],1),(_vm.showSection)?[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"px-4"},[_c('v-filters',{attrs:{"search":true,"start-open":true},on:{"reset-search":_vm.resetSearch},model:{value:(_vm.apiSearchFilter.search),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "search", $$v)},expression:"apiSearchFilter.search"}},[_c('template',{slot:"right"},[_c('base-button',{staticClass:"mr--3",attrs:{"size":"sm","type":"primary"},on:{"click":function($event){_vm.showModal = true}}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("journals.add_note")))])],1),_c('template',{slot:"default"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('date-range-picker',{ref:"picker",staticClass:"range-picker",attrs:{"opens":"right","timePicker":false,"showWeekNumbers":true,"autoApply":true,"linkedCalendars":true,"locale-data":_vm.getDateRangePickerLocale(),"ranges":_vm.getDateRangePickerRanges()},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('manual-range',{attrs:{"date-range-manual":_vm.dateRangeManual,"date-range-error":_vm.dateRangeError},on:{"exec":function($event){return _vm.execManualRange(
                                                            'apiSearchFilter',
                                                            'dateRange'
                                                        )}}})]},proxy:true},{key:"input",fn:function(picker){return [(picker.startDate)?[_vm._v(" "+_vm._s(_vm.$d( picker.startDate, "short" ))+" - "+_vm._s(_vm.$d( picker.endDate, "short" ))+" ")]:_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t( "journals.placeholder_select_date_range" )))])]}}],null,false,1656799637),model:{value:(
                                                _vm.apiSearchFilter.dateRange
                                            ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "dateRange", $$v)},expression:"\n                                                apiSearchFilter.dateRange\n                                            "}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t('journals.type'),"type":"multiselect","multiple":true,"options":_vm.journal_types},model:{value:(_vm.apiSearchFilter.type),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "type", $$v)},expression:"apiSearchFilter.type"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t('journals.user'),"type":"multiselect","multiple":true,"options":_vm.users},model:{value:(
                                                _vm.apiSearchFilter.user_id
                                            ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "user_id", $$v)},expression:"\n                                                apiSearchFilter.user_id\n                                            "}})],1)])],2)],1),(_vm.journals.length > 0)?[_c('v-table',{staticClass:"table table-mg table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"Journals.id"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "journals.tbl_hdr_id" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"Journals.created"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "journals.tbl_hdr_created" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"Users.name"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "journals.tbl_hdr_user" )))])],1),_c('v-th',[_vm._v(_vm._s(_vm.$t("journals.tbl_hdr_type")))]),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"Journals.note"},on:{"sort":function($event){return _vm.$handleSorting($event)}}},[_vm._v(_vm._s(_vm.$t( "journals.tbl_hdr_note" )))])],1)],1)],1),_c('v-tbody',_vm._l((_vm.journals),function(j){return _c('v-tr',{key:j.id},[_c('v-td',[_vm._v("#"+_vm._s(j.id))]),_c('v-td',[_vm._v(_vm._s(_vm.$d(new Date(j.created)))+" "),(
                                                    j.created != j.modified
                                                )?_c('span',{staticClass:"text-warning ml-2"},[_vm._v("("+_vm._s(_vm.$t( "journals.last_modified" ))+": "+_vm._s(_vm.$d( new Date(j.modified) ))+")")]):_vm._e()]),_c('v-td',[_vm._v(_vm._s(j.user.name))]),_c('v-td',[_vm._v(_vm._s(_vm.journal_types[j.type]))]),_c('v-td',[_c('v-newlines',{attrs:{"text":j.note}})],1),(false)?_c('v-td',[_vm._v(" "+_vm._s(j)+" ")]):_vm._e()],1)}),1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)]:_c('div',{staticClass:"alert alert-warning mx-4"},[_vm._v(" "+_vm._s(_vm.$t("journals.no_journals_found"))+" ")])],2)]:_vm._e()],2)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showModal)?_c('v-journals',{attrs:{"context":"project_id","context-id":_vm.project_id},on:{"close":function($event){_vm.showModal = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }