<script>
import VLinkForm from "@/components/Forms/VLinkForm";
import ProjectLinkService from "@/services/ProjectLinkService";
import { mapGetters } from "vuex";
import VContextComments from "@/components/Planning/Helpers/VContextComments";
import { EventBus } from "@/libraries/EventBus";
import { LinkMetas } from "@/constants/Planning.const";

export default {
    props: {
        project_id: {}
    },
    components: { VLinkForm, VContextComments },
    data() {
        return {
            loading: true,
            showForm: false,
            links: [],
            meta: {}
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        metas() {
            return LinkMetas;
        }
    },
    async created() {
        this.loading = true;
        this.apiPaginator.sort = "ProjectLinks.title";
        this.apiPaginator.direction = "asc";
        this.$apiEnableWatcher();
        await this.fetchData();
        this.loading = false;
        EventBus.$on("journal-refresh", this.fetchData);
    },
    beforeDestroy() {
        EventBus.$off("journal-refresh", this.fetchData);
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                const { data } = await ProjectLinkService.GET(params);
                this.links = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fde", e);
            }
        },
        closeReload() {
            this.fetchData();
            this.showForm = false;
        },
        setContext(ctx) {
            this.context = ctx;
            this.showForm = true;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    size="sm"
                    type="primary"
                    @click="setContext({ empty: true })"
                    >{{ $t("project_links.add_link") }}</base-button
                ></bread-bag
            >
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <template v-if="links.length > 0">
                            <v-table class="table table-mg table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="ProjectLinks.created"
                                                @sort="$handleSorting($event)"
                                            >
                                                {{
                                                    $t(
                                                        "project_links.tbl_hdr_created"
                                                    )
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="ProjectLinks.title"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "project_links.tbl_hdr_title"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="ProjectLinks.url"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "project_links.tbl_hdr_url"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Users.name"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "project_links.tbl_hdr_user"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="ProjectLinks.count_journals"
                                                @sort="$handleSorting($event)"
                                                ><i class="far fa-comments"></i
                                            ></v-sorter>
                                        </v-th>
                                        <v-th class="text-center">{{
                                            $t("project_links.tbl_hdr_actions")
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="l in links" :key="l.id">
                                        <v-td
                                            >{{
                                                $d(new Date(l.created), "short")
                                            }}
                                            <div
                                                v-if="l.created != l.modified"
                                                class="small text-warning"
                                            >
                                                {{
                                                    $t(
                                                        "project_links.last_edited"
                                                    )
                                                }}:
                                                {{
                                                    $d(
                                                        new Date(l.modified),
                                                        "short"
                                                    )
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td
                                            >{{ l.title }}
                                            <div
                                                class="small text-muted"
                                                v-if="l.meta"
                                            >
                                                {{ $t("project_links.meta") }}:
                                                {{ metas[l.meta] }}
                                            </div>
                                        </v-td>
                                        <v-td
                                            ><a :href="l.url" target="_blank"
                                                ><i
                                                    class="far fa-external-link"
                                                ></i>
                                                {{ l.url | truncate(40) }}</a
                                            >
                                        </v-td>
                                        <v-td>{{ l.user.name }}</v-td>
                                        <v-td>
                                            <v-context-comments
                                                context="project_link_id"
                                                :context-id="l.id"
                                                :count="l.count_journals"
                                            />
                                        </v-td>
                                        <v-td class="text-center"
                                            ><i
                                                class="far m-click fa-pencil fa-lg"
                                                @click="setContext(l)"
                                            ></i
                                        ></v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="mx-4 alert alert-warning" v-else>
                            {{ $t("project_links.no_links_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <portal to="modals">
            <v-link-form
                @close="showForm = close"
                @close-reload="closeReload"
                :context="context"
                :project-id="project_id"
                v-if="showForm"
            />
        </portal>
    </div>
</template>
