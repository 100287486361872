<script>
import VActionForm from "@/components/Forms/VActionForm";
import ActionService from "@/services/ActionService";
import VAction from "@/components/Planning/VAction";
import { EventBus } from "@/libraries/EventBus";
import VContextComments from "@/components/Planning/Helpers/VContextComments";
import VControl from "@/components/Planning/Helpers/VControl";

export default {
    props: {
        project_id: {}
    },
    components: { VActionForm, VAction, VContextComments, VControl },
    data() {
        return {
            loading: true,
            actions: [],
            context: { empty: true },
            showModal: false,
            selectedFilter: "active",
            meta: {},
            showSection: false
        };
    },
    async created() {
        this.loading = true;
        this.apiPaginator.sort = "deadline";
        this.apiPaginator.direction = "asc";
        await this.fetchData();
        this.loading = false;
        EventBus.$on("journal-refresh", this.fetchData);
        EventBus.$on("data-tab-open", () => {
            this.showSection = true;
        });
        EventBus.$on("data-tab-close", () => {
            this.showSection = false;
        });
    },
    beforeDestroy() {
        EventBus.$off("journal-refresh", this.fetchData);
        EventBus.$off("data-tab-open");
        EventBus.$off("data-tab-close");
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;

                if (this.selectedFilter == "active") {
                    params["status"] = "0";
                } else if (this.selectedFilter == "done") {
                    params["status"] = "5";
                }

                const { data } = await ActionService.GET(params);
                this.actions = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        async toggleStatus(action) {
            this.$swal({
                icon: "warning",
                text:
                    action.status === 0
                        ? this.$t("actions.swal_change_to_done")
                        : this.$t("actions.swal_change_to_in_progress"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t(
                                "actions.notify_action_status_changed"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });

                        await ActionService.PUT_toggleStatus(action.id);
                        this.fetchData();
                    } catch (e) {}
                }
            });
        },
        async closeReload() {
            this.showModal = false;
            this.fetchData();
        },
        setContext(ctx) {
            this.context = ctx;
            this.showModal = true;
        },
        filter(orderCmd) {
            this.selectedFilter = orderCmd;
            this.fetchData();
        }
    }
};
</script>

<template>
    <div class="v-important-dates">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-3">
                        <v-control
                            :show="showSection"
                            @trigger="showSection = !showSection"
                        />

                        <h3 class="pb-0 mb-0">
                            {{ $t("actions.action_log") }}
                        </h3>
                    </div>

                    <template v-if="showSection">
                        <v-loader :loading="loading" v-if="loading" />
                        <card body-classes="px-0" v-else>
                            <div
                                class="mx-4 mb-3 d-flex justify-content-between"
                            >
                                <div>
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'active'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('active')"
                                        >{{
                                            $t("actions.filter_active")
                                        }}</base-button
                                    >
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'done'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('done')"
                                        >{{
                                            $t("actions.filter_done")
                                        }}</base-button
                                    >
                                    <base-button
                                        size="sm"
                                        :type="
                                            selectedFilter == 'all'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        @click="filter('all')"
                                        >{{
                                            $t("actions.filter_all")
                                        }}</base-button
                                    >
                                </div>
                                <div>
                                    <base-button
                                        size="sm"
                                        type="primary"
                                        @click="setContext({ empty: true })"
                                        ><i class="far fa-plus"></i>
                                        {{ $t("actions.add_new") }}</base-button
                                    >
                                </div>
                            </div>

                            <div v-if="actions.length > 0">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th style="width: 50px">
                                                <i class="far fa-exclamation-triangle"></i>
                                            </v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="description"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "actions.hdr_description"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="deadline"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "actions.hdr_deadline"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Users.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("actions.hdr_user")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="status"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("actions.hdr_status")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="a in actions"
                                            :key="a.id"
                                            :class="{
                                                'task-done': a.status === 5
                                            }"
                                        >
                                            <v-td>
                                                <i
                                                    class="fas fa-exclamation-triangle text-danger"
                                                    v-if="a.priority"
                                                ></i>
                                            </v-td>
                                            <v-td
                                                ><v-newlines
                                                    :text="a.description"
                                                />
                                            </v-td>
                                            <v-td>
                                                <template v-if="a.deadline">
                                                    {{
                                                        $d(
                                                            new Date(
                                                                a.deadline
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>---</template>
                                            </v-td>
                                            <v-td>{{ a.user.name }} </v-td>
                                            <v-td
                                                ><i
                                                    class="fa-check-circle fa-fw fa-lg m-click"
                                                    @click="toggleStatus(a)"
                                                    :class="{
                                                        'far text-muted':
                                                            a.status === 0,
                                                        'fas text-success':
                                                            a.status === 5
                                                    }"
                                                ></i
                                            ></v-td>
                                            <v-td style="width: 100px;">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <v-context-comments
                                                            context="action_id"
                                                            :context-id="a.id"
                                                            :count="
                                                                a.count_journals
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col-6">
                                                        <i
                                                            class="fa-pencil fa-fw far m-click"
                                                            @click="
                                                                setContext(a)
                                                            "
                                                        ></i>
                                                    </div>
                                                </div>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>

                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </div>
                            <div class="mx-4 alert alert-warning" v-else>
                                {{ $t("actions.no_actions_found") }}
                            </div>
                        </card>
                    </template>
                </div>
            </div>
        </div>

        <portal to="modals">
            <v-action-form
                :context="context"
                @close="showModal = false"
                @close-reload="closeReload"
                :project-id="project_id"
                v-if="showModal"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-important-dates {
    tr.task-done {
        background-color: #f7f7f7;
    }
}
</style>
