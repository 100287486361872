<script>
export default {
    props: {
        done: {
            type: Number,
            default: 0
        },
        count: {
            type: Number,
            default: 0
        }
    }
};
</script>

<template>
    <div class="v-task-progress">
        <div v-if="count == 0"></div>
        <div v-else :class="{'text-success text-bold': done == count}">{{ done }}/{{ count }}</div>
    </div>
</template>

<style lang="scss">
.v-task-progress {
    text-align: center;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
</style>
