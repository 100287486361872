<script>
import VFilters from "@/components/Search/VFilters";
import JournalService from "@/services/JournalService";
import { JournalTypes } from "@/constants/Journal.const";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AppCfg from "@/mixins/AppCfg";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import UserService from "@/services/UserService";
import VJournals from "@/components/Journals/VJournals";
import VControl from "@/components/Planning/Helpers/VControl";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        project_id: {}
    },
    mixins: [DateRangeMixin, AppCfg],
    components: { VFilters, DateRangePicker, VJournals, VControl },
    data() {
        return {
            loading: true,
            journals: [],
            meta: {},
            users: [],
            showModal: false,
            showSection: false
        };
    },
    computed: {
        journal_types() {
            return JournalTypes;
        }
    },
    async created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchUsers();
        await this.fetchData();
        this.loading = false;
        EventBus.$on("journal-refresh", this.fetchData);
        EventBus.$on("data-tab-open", () => {
            this.showSection = true;
        });
        EventBus.$on("data-tab-close", () => {
            this.showSection = false;
        });
    },
    beforeDestroy() {
        EventBus.$off("journal-refresh", this.fetchData);
        EventBus.$off("data-tab-open");
        EventBus.$off("data-tab-close");
    },
    methods: {
        resetSearch() {
            this.apiSearchFilter = {
                search: "",
                type: ["project"],
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };

            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "Journals.created"
            };
        },
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                const { data } = await JournalService.GET(params);
                this.journals = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fe e", e);
            }
        },
        async fetchUsers() {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>

<template>
    <div class="v-journals">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-3">
                        <v-control
                            :show="showSection"
                            @trigger="showSection = !showSection"
                        />

                        <h3 class="pb-0 mb-0">
                            {{ $t("progress_log.project_journal") }}
                        </h3>
                    </div>

                    <template v-if="showSection">
                        <v-loader :loading="loading" v-if="loading" />
                        <card body-classes="px-0" v-else>
                            <div class="px-4">
                                <v-filters
                                    :search="true"
                                    :start-open="true"
                                    v-model="apiSearchFilter.search"
                                    @reset-search="resetSearch"
                                >
                                    <template slot="right">
                                        <base-button
                                            size="sm"
                                            class="mr--3"
                                            type="primary"
                                            @click="showModal = true"
                                            ><i class="far fa-plus"></i>
                                            {{
                                                $t("journals.add_note")
                                            }}</base-button
                                        >
                                    </template>
                                    <template slot="default">
                                        <div class="col-12 col-md-4">
                                            <date-range-picker
                                                class="range-picker"
                                                ref="picker"
                                                opens="right"
                                                :timePicker="false"
                                                :showWeekNumbers="true"
                                                :autoApply="true"
                                                v-model="
                                                    apiSearchFilter.dateRange
                                                "
                                                :linkedCalendars="true"
                                                :locale-data="
                                                    getDateRangePickerLocale()
                                                "
                                                :ranges="
                                                    getDateRangePickerRanges()
                                                "
                                            >
                                                <template #header>
                                                    <manual-range
                                                        :date-range-manual="
                                                            dateRangeManual
                                                        "
                                                        :date-range-error="
                                                            dateRangeError
                                                        "
                                                        @exec="
                                                            execManualRange(
                                                                'apiSearchFilter',
                                                                'dateRange'
                                                            )
                                                        "
                                                    />
                                                </template>
                                                <template
                                                    v-slot:input="picker"
                                                    style="min-width: 350px"
                                                >
                                                    <template
                                                        v-if="picker.startDate"
                                                    >
                                                        {{
                                                            $d(
                                                                picker.startDate,
                                                                "short"
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            $d(
                                                                picker.endDate,
                                                                "short"
                                                            )
                                                        }}
                                                    </template>
                                                    <span
                                                        class="text-muted"
                                                        v-else
                                                        >{{
                                                            $t(
                                                                "journals.placeholder_select_date_range"
                                                            )
                                                        }}</span
                                                    >
                                                </template>
                                            </date-range-picker>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <FormulateInput
                                                :placeholder="
                                                    $t('journals.type')
                                                "
                                                v-model="apiSearchFilter.type"
                                                type="multiselect"
                                                :multiple="true"
                                                :options="journal_types"
                                            />
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <FormulateInput
                                                :placeholder="
                                                    $t('journals.user')
                                                "
                                                v-model="
                                                    apiSearchFilter.user_id
                                                "
                                                type="multiselect"
                                                :multiple="true"
                                                :options="users"
                                            />
                                        </div>
                                    </template>
                                </v-filters>
                            </div>

                            <template v-if="journals.length > 0">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Journals.id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "journals.tbl_hdr_id"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Journals.created"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "journals.tbl_hdr_created"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Users.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "journals.tbl_hdr_user"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th>{{
                                                $t("journals.tbl_hdr_type")
                                            }}</v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Journals.note"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "journals.tbl_hdr_note"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="j in journals" :key="j.id">
                                            <v-td>#{{ j.id }}</v-td>
                                            <v-td
                                                >{{ $d(new Date(j.created)) }}
                                                <span
                                                    class="text-warning ml-2"
                                                    v-if="
                                                        j.created != j.modified
                                                    "
                                                    >({{
                                                        $t(
                                                            "journals.last_modified"
                                                        )
                                                    }}:
                                                    {{
                                                        $d(
                                                            new Date(j.modified)
                                                        )
                                                    }})</span
                                                >
                                            </v-td>
                                            <v-td>{{ j.user.name }}</v-td>
                                            <v-td>{{
                                                journal_types[j.type]
                                            }}</v-td>
                                            <v-td
                                                ><v-newlines :text="j.note"
                                            /></v-td>
                                            <v-td v-if="false">
                                                {{ j }}
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>

                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </template>
                            <div class="alert alert-warning mx-4" v-else>
                                {{ $t("journals.no_journals_found") }}
                            </div>
                        </card>
                    </template>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-journals
                v-if="showModal"
                @close="showModal = false"
                context="project_id"
                :context-id="project_id"
            />
        </portal>
    </div>
</template>
