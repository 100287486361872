<script>
import VSubitemStatus from "./Helpers/VSubitemStatus";
import VContextComments from "./Helpers/VContextComments";

export default {
    props: {
        subitem: {}
    },
    components: { VSubitemStatus, VContextComments }
};
</script>

<template>
    <div class="v-subitem">
        <div class="v-subitem_name">
            {{ subitem.name }}
        </div>
        <div class="v-subitem_comments">
            <v-context-comments
                context="project_subitem_id"
                :context-id="subitem.id"
                :count="subitem.count_journals"
            />
        </div>
        <div class="v-subitem_status">
            <v-subitem-status :subitem="subitem" />
        </div>
    </div>
</template>

<style lang="scss">
.v-subitem {
    margin-left: 25px;
    display: flex;
    max-width: 1000px;
    align-items: center;
    flex-direction: row;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-radius: 6px;
    margin-bottom: 0px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:last-child {
        border-bottom: 1px solid $border-color;
    }

    &_name {
        padding-left: 10px;
        padding-right: 15px;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &_comments {
        flex: 0 0 70px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &_status {                
        flex: 0 0 240px;
        overflow: auto;
    }
}
</style>
