var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-action",class:{
        'past-deadline': _vm.pastDeadline,
        'is-today': _vm.isToday,
        'status-done': _vm.action.status === 5,
        'status-draft': _vm.action.status === 0
    }},[_c('div',{staticClass:"v-action_id"},[_vm._v("#"+_vm._s(_vm.action.id))]),_c('div',{staticClass:"v-action_user"},[_vm._v(_vm._s(_vm.action.user.name))]),_c('div',{staticClass:"v-action_deadline"},[(_vm.action.status === 0 || _vm.showDeadline)?_c('div',{on:{"mouseleave":function($event){_vm.showDeadline = false}}},[_c('i',{staticClass:"far fa-fw fa-calendar-alt mr-1"}),(_vm.action.deadline)?[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.action.deadline), "short"))+" ")]:_c('span',{staticClass:"ml-2"},[_vm._v("---")])],2):_c('div',{on:{"mouseover":function($event){_vm.showDeadline = true}}},[_c('i',{staticClass:"fas fa-fw fa-check-circle text-success mr-1"}),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.action.done_date), "short"))+" ")])]),_c('div',{staticClass:"v-action_text"},[_vm._v(_vm._s(_vm.action.description))]),_c('div',{staticClass:"v-action_status"},[_c('v-context-comments',{attrs:{"context":"action_id","context-id":_vm.action.id,"count":_vm.action.count_journals}})],1),_c('div',{staticClass:"v-action_status"},[_c('i',{staticClass:"fa-check-circle fa-fw m-click",class:{
                'far text-muted': _vm.action.status === 0,
                'fas text-success': _vm.action.status === 5
            },on:{"click":function($event){return _vm.$emit('toggle-status')}}})]),_c('div',{staticClass:"v-action_btns"},[_c('i',{staticClass:"fa-pencil fa-fw far fa-lg m-click",on:{"click":function($event){return _vm.$emit('edit')}}})]),(false)?_c('pre',[_vm._v(_vm._s(_vm.action))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }