<script>
import Cloner from "@/libraries/Cloner";
import ImportantDateService from "@/services/ImportantDateService";

export default {
    props: {
        context: {},
        projectId: {}
    },
    data() {
        return {
            editing: false,
            loading: true,
            errors: {},
            entity: {
                id: null,
                project_id: null,
                user_id: null,
                description: null,
                date_start: null,
                date_end: null
            }
        };
    },
    created() {
        if (this.context.empty) {
            this.editing = false;
        } else {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "project_id",
                "user_id",
                "description",
                "date_start",
                "date_end"
            ]);
        }

        this.entity.project_id = this.projectId;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    await ImportantDateService.PUT(this.entity);
                } else {
                    await ImportantDateService.POST(this.entity);
                }

                this.$emit("close-reload");
            } catch (e) {
                this.errors = e.response.data.errors;
                console.log("save e ", e);
            }
        },
        async deleteEntity() {
            this.$swal({
                icon: "warning",
                text: this.$t("important_dates.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t(
                                "important_dates.notify_journal_deleted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        await ImportantDateService.DELETE(this.context.id);

                        this.$emit("close-reload");
                        // Just remove - no need to reload
                    } catch (e) {}
                }
            });
        }
    }
};
</script>

<template>
    <modal size="xl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <template v-if="editing"
                >{{ $t("important_dates.edit_date") }}
            </template>
            <template v-else>{{ $t("important_dates.create_date") }}</template>
        </template>

        <template slot="default">
            <div class="small mb-3" v-if="editing">
                {{ $t("important_dates.created_by") }}:
                <b>{{ context.user.name }}</b>
                {{ $t("important_dates.on") }}
                <b>{{ $d(new Date(context.created)) }}</b>
            </div>
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            type="date"
                            name="date_start"
                            :label="$t('important_dates.date_start')"
                            validation="required"
                        />
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            type="date"
                            name="date_end"
                            :label="$t('important_dates.date_end')"
                            :help="$t('important_dates.date_end_help')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :placeholder="
                                $t('important_dates.description_placeholder')
                            "
                            :label="$t('important_dates.description')"
                            rows="5"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <base-button
                            type="danger"
                            :outline="true"
                            @click="deleteEntity"
                            v-if="editing"
                            ><i class="far fa-trash"></i>
                            {{ $t("delete") }}</base-button
                        >
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
