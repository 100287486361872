<script>
import VContextComments from "@/components/Planning/Helpers/VContextComments";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
dayjs.extend(isToday);

export default {
    props: {
        action: {}
    },
    components: { VContextComments },
    data() {
        return {
            showDeadline: false
        };
    },
    computed: {
        pastDeadline() {
            if (!this.action.deadline || this.action.status === 5) {
                return false;
            }

            return dayjs().isAfter(this.action.deadline, "day");
        },
        isToday() {
            if (!this.action.deadline || this.action.status === 5) {
                return false;
            }

            return dayjs(this.action.deadline).isToday();
        }
    }
};
</script>

<template>
    <div
        class="v-action"
        :class="{
            'past-deadline': pastDeadline,
            'is-today': isToday,
            'status-done': action.status === 5,
            'status-draft': action.status === 0
        }"
    >
        <div class="v-action_id">#{{ action.id }}</div>
        <div class="v-action_user">{{ action.user.name }}</div>
        <div class="v-action_deadline">
            <div
                v-if="action.status === 0 || showDeadline"
                @mouseleave="showDeadline = false"
            >
                <i class="far fa-fw fa-calendar-alt mr-1"></i>
                <template v-if="action.deadline">
                    {{ $d(new Date(action.deadline), "short") }}
                </template>
                <span class="ml-2" v-else>---</span>
            </div>

            <div @mouseover="showDeadline = true" v-else>
                <i class="fas fa-fw fa-check-circle text-success mr-1"></i>
                {{ $d(new Date(action.done_date), "short") }}
            </div>
        </div>
        <div class="v-action_text">{{ action.description }}</div>
        <div class="v-action_status">
            <v-context-comments
                context="action_id"
                :context-id="action.id"
                :count="action.count_journals"
            />
        </div>
        <div class="v-action_status">
            <i
                class="fa-check-circle fa-fw m-click"
                @click="$emit('toggle-status')"
                :class="{
                    'far text-muted': action.status === 0,
                    'fas text-success': action.status === 5
                }"
            ></i>
        </div>
        <div class="v-action_btns">
            <i
                class="fa-pencil fa-fw far fa-lg m-click"
                @click="$emit('edit')"
            ></i>
        </div>
        <pre v-if="false">{{ action }}</pre>
    </div>
</template>

<style lang="scss">
.v-action {
    display: flex;
    align-items: center;
    border: 1px solid $gray-500;
    border-radius: 6px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;

    &.status-done {
        background: $gray-100;
        border: 1px solid $gray-300;
    }

    &.past-deadline {
        border: 1px solid $danger;
        .v-action_deadline {
            color: $danger !important;
        }
    }

    &.is-today {
        border: 1px solid $warning;
        .v-action_deadline {
            color: $warning !important;
        }
    }

    &_id {
        flex: 0 0 70px;

        text-align: center;
    }

    &_user {
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 180px;
    }

    &_deadline {
        text-align: left;
        padding-left: 25px;
        flex: 0 0 140px;
    }

    &_text {
        padding-left: 15px;
        flex: 1 0;
    }

    &_status {
        i {
            font-size: 22px;
        }
        text-align: center;
        flex: 0 0 40px;
    }

    &_btns {
        text-align: center;
        flex: 0 0 40px;
    }
}
</style>
