<script>
import Cloner from "@/libraries/Cloner";
import ActionService from "@/services/ActionService";
import UserService from "@/services/UserService";

export default {
    props: {
        context: {},
        projectId: {}
    },
    data() {
        return {
            editing: false,
            loading: true,
            errors: {},
            users: [],
            entity: {
                id: null,
                user_id: null,
                project_id: null,
                status: null,
                deadline: null,
                description: null,
                weight: null,
                priority: null
            }
        };
    },
    async created() {
        await this.fetchUsers();

        if (this.context.empty) {
            this.editing = false;
        } else {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "user_id",
                "project_id",
                "status",
                "deadline",
                "description",
                "weight",
                "priority"
            ]);
        }

        this.entity.project_id = this.projectId;

        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    await ActionService.PUT(this.entity);
                } else {
                    await ActionService.POST(this.entity);
                }

                this.$emit("close-reload");
            } catch (e) {
                this.errors = e.response.data.errors;
                console.log("save e ", e);
            }
        },
        async deleteEntity() {
            this.$swal({
                icon: "warning",
                text: this.$t("actions.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t("actions.notify_action_deleted"),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        await ActionService.DELETE(this.context.id);
                        this.$emit("close-reload");
                    } catch (e) {}
                }
            });
        },
        async fetchUsers() {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
        }
    }
};
</script>

<template>
    <modal size="" :show="true" @close="$emit('close')" class="budget-modal" :loading="loading">
        <template slot="header">
            <template v-if="editing">{{ $t("actions.edit_action") }}</template>
            <template v-else>{{ $t("actions.create_action") }}</template>
        </template>

        <template slot="default">
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="priority"
                            type="fswitch"
                            value="1"
                            :label="$t('actions.is_priority_action')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="user_id"
                            type="select"
                            :options="users"
                            :label="$t('actions.user')"
                            :validation-name="$t('actions.user')"
                            :placeholder="$t('actions.placeholder_users')"
                            validation="required"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            type="date"
                            name="deadline"
                            :label="$t('actions.deadline')"
                            validation=""
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :placeholder="
                                $t('important_dates.description_placeholder')
                            "
                            :label="$t('important_dates.description')"
                            rows="8"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <base-button
                            type="danger"
                            :outline="true"
                            v-if="editing"
                            @click="deleteEntity"
                            ><i class="far fa-trash"></i>
                            {{ $t("delete") }}</base-button
                        >
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
