<script>
/**
 * This components does context-aware comments
 */

import VJournals from "@/components/Journals/VJournals";

export default {
    props: {
        context: {
            description: "Key name, i.e. project_milestone_id"
        },
        contextId: {
            description: "Key ID"
        },
        count: {
            type: Number,
            default: 0
        }
    },
    components: { VJournals },
    data() {
        return {
            hover: false,
            showModal: false
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        }
    }
};
</script>

<template>
    <div class="v-context-comments d-flex align-items-center">
        <i
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @click="openModal"
            class="far fa-fw"
            :class="{
                'fa-comments': !hover,
                'fa-comment-plus m-click add-comments': hover,
                'fa-muted': count == 0 || isNaN(parseInt(count))
            }"
        ></i>
        <span v-if="count > 0"class="le-count">{{ count }}</span>
        <portal to="modals">
            <v-journals
                v-if="showModal"
                @close="closeModal"
                :context="context"
                :context-id="contextId"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-context-comments {
    .le-count {
        font-size: 10px;
        padding-left: 5px;
    }

    .add-comments.fa-muted,
    .add-comments {
        color: $gray-800 !important;
    }
}
</style>
