<script>
import Cloner from "@/libraries/Cloner";
import MGConsts from "@/mixins/MGConsts";
import ProjectFlagService from "@/services/ProjectFlagService";
import VFlag from "@/components/Planning/Flagging/VFlag";
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import { mapGetters } from "vuex";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        projectId: {}
    },
    mixins: [MGConsts],
    components: { VFlag },
    data() {
        return {
            editing: false,
            selectedFilter: "",
            showAdd: false,
            loading: true,
            errors: {},
            saving: false,
            flags: [],
            meta: {},
            entity: {
                id: null,
                project_id: null,
                flag_type: null,
                subtype: "flag",
                description: null,
                description2: null
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        filters() {
            return [
                {
                    filter: "",
                    name: this.$t("flagging.f_type_all")
                },
                {
                    filter: "5",
                    name: this.$t("flagging.f_type_active")
                },
                {
                    filter: "1",
                    name: this.$t("flagging.f_type_awaiting")
                },
                {
                    filter: "2",
                    name: this.$t("flagging.f_type_denied")
                }
            ];
        },
        listFlags() {
            return FlagTypes;
        },
        flagTypes() {
            return this.$hash2select(FlagTypes);
        },
        flagDetails() {
            return FlagTypesDetailed;
        },
        flagIcons() {
            return FlagTypesIcons;
        },
        flagStatus() {
            return FlagStatus;
        },
        flagSubtypes() {
            return FlagSubtypes;
        }
    },
    async created() {
        this.entity.project_id = this.projectId;
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.projectId;

                if (this.selectedFilter !== "") {
                    params["status"] = this.selectedFilter;
                }
                const { data } = await ProjectFlagService.GET(params);
                this.flags = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        async saveForm() {
            try {
                this.saving = true;
                if (this.editing) {
                    // editing
                    await ProjectFlagService.PUT(this.entity);
                } else {
                    // creating
                    await ProjectFlagService.POST(this.entity);
                    EventBus.$emit("project-refresh");
                    this.$notify({
                        message: this.$t("flagging.notify_flag_created"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                }

                this.fetchData();
                this.showAdd = false;
            } catch (e) {
                console.log("sf e", e);
                this.errors = e.response.data.errors;
            }
            this.saving = false;
        },
        /**
         * Update the filter -> run fetch after
         * @param type
         */
        updateFilter(type) {
            this.selectedFilter = type;
            this.fetchData();
        },
        canEdit(flag) {
            if (flag.status === 1 && this.getMyId === flag.user_id) {
                return true;
            } else {
                return false;
            }
        },
        editFlag(flag) {
            this.editing = true;
            this.showAdd = true;
            this.entity = Cloner.pickClone(flag, [
                "id",
                "flag_type",
                "description",
                "description2"
            ]);
        },
        createFlag() {
            this.editing = false;
            this.resetFlagData();
            this.showAdd = true;
        },
        cancelFlag() {
            this.resetFlagData();
            this.showAdd = false;
        },
        resetFlagData() {
            this.entity["id"] = null;
            this.entity["flag_type"] = null;
            this.entity["description"] = null;
            this.entity["description2"] = null;
        },
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("flagging.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await ProjectFlagService.DELETE(
                                this.entity.id
                            );
                            this.resetFlagData();
                            this.showAdd = false;
                            EventBus.$emit("project-refresh");
                            this.fetchData();
                        } catch (e) {
                            this.$swal({
                                icon: "error",
                                title: this.$t("error_cannot_delete"),
                                text: this.$t("unknown_error")
                            });
                        }
                    }
                });
            }
        }
    }
};
</script>

<template>
    <modal size="xxl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <div>{{ $t("flagging.manage_and_create_project_flags") }}</div>
        </template>

        <template slot="default">
            <div
                class="d-flex align-items-center justify-content-between mb-4"
                v-if="!showAdd"
            >
                <div>
                    <base-button
                        v-for="(filter, idx) in filters"
                        :key="idx"
                        size="sm"
                        :type="
                            selectedFilter == filter.filter
                                ? 'primary'
                                : 'secondary'
                        "
                        @click="updateFilter(filter.filter)"
                        >{{ filter.name }}</base-button
                    >
                </div>
                <div>
                    <base-button type="primary" size="sm" @click="createFlag"
                        ><i class="far fa-plus"></i>
                        {{ $t("flagging.add_new_flag") }}</base-button
                    >
                </div>
            </div>

            <div class="flag-form-container" v-if="showAdd">
                <!-- flag creation -->
                <FormulateForm
                    v-model="entity"
                    @submit="saveForm"
                    :errors="errors"
                >
                    <div class="row">
                        <div class="col-6">
                            <FormulateInput
                                name="flag_type"
                                type="select"
                                :options="flagTypes"
                                :validation-name="$t('flagging.flag_type')"
                                :label="$t('flagging.flag_type')"
                                validation="required"
                            />
                        </div>
                        <div class="col-6">
                            <h4>{{ $t("flagging.flag_types") }}</h4>
                            <div
                                v-for="(flag, index) in listFlags"
                                :key="index"
                                class="mb-2"
                            >
                                <i :class="flagIcons[index]" class="mr-2"></i>
                                {{ flagDetails[index] }}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                name="description"
                                type="textarea"
                                :placeholder="$t('flagging.placeholder_impact')"
                                :label="$t('flagging.impact')"
                                validation="required"
                                rows="6"
                            />
                        </div>
                        <div class="col-12">
                            <FormulateInput
                                name="description2"
                                type="textarea"
                                :placeholder="
                                    $t('flagging.placeholder_mitigation')
                                "
                                :label="$t('flagging.mitigation')"
                                validation="required"
                                rows="6"
                            />
                        </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                        <div>
                            <base-button
                                v-if="editing"
                                type="danger"
                                :outline="true"
                                @click="deleteEntity"
                                ><i class="far fa-trash"></i>
                                {{ $t("delete") }}</base-button
                            >
                        </div>
                        <div>
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="cancelFlag"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button
                                type="primary"
                                :loading="saving"
                                native-type="submit"
                                >{{ $t("save") }}</base-button
                            >
                        </div>
                    </div>
                </FormulateForm>
            </div>
            <div class="" v-if="!showAdd">
                <!-- flag listing -->
                <v-loader :loading="loading" v-if="loading" />
                <template v-else-if="flags.length > 0">
                    <v-table class="table table-mg table-sm table-striped">
                        <v-thead>
                            <v-tr>
                                <v-th>{{
                                    $t("flagging.tbl_hdr_date_and_user")
                                }}</v-th>
                                <v-th>{{
                                    $t("flagging.tbl_hdr_flag_type")
                                }}</v-th>
                                <v-th>{{ $t("flagging.tbl_hdr_status") }}</v-th>
                                <v-th>{{ $t("flagging.tbl_hdr_impact") }}</v-th>
                                <v-th>{{
                                    $t("flagging.tbl_hdr_mitigation")
                                }}</v-th>
                                <v-th></v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="(f, idx) in flags" :key="f.id">
                                <v-td
                                    >{{ $d(new Date(f.created), "short") }}
                                    <div>{{ f.user.name }}</div>
                                </v-td>
                                <v-td
                                    ><v-flag :flag="f.flag_type" />
                                    <span class="ml-1">{{
                                        flagSubtypes[f.subtype]
                                    }}</span>
                                    <div class="small">
                                        {{ flagDetails[f.flag_type] }}
                                    </div></v-td
                                >
                                <v-td>{{ flagStatus[f.status] }}</v-td>
                                <v-td>
                                    <template
                                        v-if="f.status === 5 || f.status === 2"
                                    >
                                        <div class="text-bold">
                                            {{
                                                $t("flagging.by_USER_on_DATE", {
                                                    USER: f.done_user.name,
                                                    DATE: $d(
                                                        new Date(f.done_date),
                                                        "short"
                                                    )
                                                })
                                            }}:
                                        </div>
                                        <div>
                                            <v-newlines
                                                :text="f.done_description"
                                            />
                                        </div>
                                        <hr />
                                        <div class="text-bold">
                                            {{ $t("flagging.original_text") }}:
                                        </div>
                                    </template>
                                    <v-newlines :text="f.description" />
                                </v-td>
                                <v-td>
                                    <v-newlines :text="f.description2" />
                                </v-td>
                                <v-td>
                                    <i
                                        class="far fa-lg fa-fw fa-pencil m-click"
                                        v-if="canEdit(f)"
                                        @click="editFlag(f)"
                                    ></i
                                ></v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>

                    <div class="paginator d-flex justify-content-center">
                        <base-pagination
                            :page-count="meta.pagination.total_pages"
                            :per-page="meta.pagination.per_page"
                            :value="meta.pagination.current_page"
                            :total="meta.pagination.total"
                            @input="$handlePagination($event)"
                        />
                    </div>
                </template>
                <div class="alert alert-warning" v-else>
                    {{ $t("flagging.no_flags_found") }}
                </div>
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
.flag-form-container {
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 40px;
}
</style>
