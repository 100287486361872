<script>
import VSubitem from "./VSubitem";
import VControl from "./Helpers/VControl";
import VStatus from "./Helpers/VStatus";
import VTimeline from "./Helpers/VTimeline";
import VTaskProgress from "./Helpers/VTaskProgress";
import VDocumentation from "./Helpers/VDocumentation";
import VContextComments from "./Helpers/VContextComments";
import VDependency from "./Helpers/VDependency";
import VDcReview from "./Helpers/VDcReview";
import { EventBus } from "@/libraries/EventBus";
import VTimelineEditForm from "@/components/Forms/VTimelineEditForm";

export default {
    props: {
        milestone: {},
        stage: {}
    },
    components: {
        VSubitem,
        VControl,
        VStatus,
        VTimeline,
        VDocumentation,
        VTaskProgress,
        VContextComments,
        VDcReview,
        VTimelineEditForm,
        VDependency
    },
    data() {
        return {
            showItems: false,
            showEditTimeline: false
        };
    },
    created() {
        EventBus.$on("milestones-board", this.handleMilestonesBoard);
    },
    beforeDestroy() {
        EventBus.$off("milestones-board", this.handleMilestonesBoard);
    },
    methods: {
        editTimeline() {
            this.showEditTimeline = true;
        },
        handleMilestonesBoard({ action }) {
            if (!this.milestone.project_subitems) {
                return;
            }

            if (action === "open") {
                this.showItems = true;
            } else {
                this.showItems = false;
            }
        },
        triggerShow(newShow) {
            this.showItems = newShow;
        }
    }
};
</script>

<template>
    <div class="v-milestone">
        <div class="vp-milestone-table">
            <div class="vp-milestone-ctrl">
                <v-control
                    :show="showItems"
                    @trigger="triggerShow"
                    :disable="!milestone.project_subitems"
                />
            </div>
            <div class="vp-milestone-ident">
                M{{ String(milestone.m_ident).padStart(2, "0") }}
            </div>
            <div class="vp-milestone-name">
                {{ milestone.name }}
                <v-dc-review :context="milestone.dc_review" />
            </div>
            <div class="vp-milestone-task-progress">
                <v-task-progress
                    :done="milestone.done_subitems"
                    :count="
                        'project_subitems' in milestone
                            ? milestone.project_subitems.length
                            : 0
                    "
                />
            </div>
            <div class="vp-milestone-status">
                <v-status :milestone="milestone" />
            </div>
            <div class="vp-milestone-comments">
                <v-context-comments
                    :count="milestone.count_journals"
                    context="project_milestone_id"
                    :context-id="milestone.id"
                />
            </div>
            <div class="vp-milestone-doc" v-if="false">
                <v-documentation
                    :doc="milestone.documentation_text"
                    :milestone-id="milestone.id"
                />
            </div>
            <div class="vp-milestone-timeline">
                <v-timeline
                    :milestone="milestone"
                    :status="milestone.status"
                    :progress="milestone.timeline_progress"
                    @timeline-editor="editTimeline"
                />
            </div>
            <div class="vp-milestone-duration">
                <div class="vp-label">{{ $t("planning.label_duration") }}</div>
                <div class="vp-value">
                    <template v-if="milestone.duration">
                        {{ milestone.duration }} {{$t('planning.week_short')}}
                    </template>
                    <span v-else class="text-danger">
                        0 {{$t('planning.week_short')}}
                    </span>
                </div>
            </div>
            <div class="vp-milestone-dep">
                <div class="vp-label">
                    {{ $t("planning.label_dependency") }}
                </div>
                <div class="vp-value">
                    <v-dependency :context="milestone.dependency" />
                </div>
            </div>
            <div class="vp-milestone-gov" v-if="false">
                <div class="vp-label">
                    {{ $t("planning.label_governance") }}
                </div>
                <div class="vp-value"><v-empty :text="stage.governance" /></div>
            </div>
            <div class="vp-milestone-dc" v-if="false">
                <div class="vp-label">{{ $t("planning.label_dc_review") }}</div>
                <div class="vp-value">
                    <v-empty :text="milestone.dc_review" />
                </div>
            </div>
        </div>
        <div class="vp-subitems-container" v-if="showItems">
            <v-subitem
                v-for="subitem in milestone.project_subitems"
                :key="subitem.id"
                class="vp-subitem"
                :subitem="subitem"
            >
            </v-subitem>
        </div>
        <portal to="modals">
            <v-timeline-edit-form
                v-if="showEditTimeline"
                :project-id="milestone.project_id"
                @close="showEditTimeline = false"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-milestone {
    margin-bottom: 15px;
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 10px;
    position: relative;

    .vp-milestone-table {
        display: flex;
        align-items: center;
        div {
        }
    }

    .vp-label {
        font-size: 10px;
        color: $gray-600;
        height: 20px;
    }

    .vp-subitems-container {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .vp-milestone-ctrl {
        flex: 0 0 30px;
    }

    .vp-milestone-ident {
        flex: 0 0 46px;
        font-size: 18px;
        color: $gray-600;
        font-weight: bold;
    }

    .vp-milestone-name {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .vp-milestone-task-progress {
        flex: 0 0 70px;
    }

    .vp-milestone-status {
        flex: 0 0 90px;
    }

    .vp-milestone-doc {
        text-align: center;
        flex: 0 0 50px;
        padding-left: 0px;
        padding-right: 15px;
        // border: 1px solid red;
    }

    .vp-milestone-comments {
        text-align: center;
        flex: 0 0 50px;
        padding-left: 15px;
        padding-right: 0px;
        // border: 1px solid red;
    }

    .vp-milestone-timeline {
        flex: 0 0 230px;
        //  border: 1px solid red;
    }

    .vp-milestone-duration {
        font-size: 12px;
        text-align: center;
        flex: 0 0 50px;
        padding-left: 15px;
        padding-right: 15px;
        // border: 1px solid red;
    }

    .vp-milestone-dep {
        font-size: 12px;
        text-align: center;
        flex: 0 0 80px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .vp-milestone-gov {
        font-size: 12px;
        text-align: center;
        flex: 0 0 160px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .vp-milestone-dc {
        font-size: 12px;
        text-align: center;
        flex: 0 0 160px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
</style>
